<template>
    <loader v-bind="{ loading }" text="Loading Attachments">
        <columns>
            <column>
                <form>
                    <text-input v-model="filters.search" classes="has-addons is-rounded"
                        placeholder="Search for attachments...">
                        <template #right>
                            <div class="buttons has-addons is-rounded">
                                <action-button v-if="Object.values($route.query).length" @click="clearFilters"
                                    class="is-rounded">
                                    <icon icon="times" />
                                </action-button>
                                <submit-button @submit="runSearch" class="is-rounded">
                                    Search
                                </submit-button>
                                <action-button left-icon="plus" @click="openUploader" class="is-rounded">
                                    Attachment
                                </action-button>
                            </div>
                        </template>
                    </text-input>
                </form>
            </column>
        </columns>
        <columns>
            <column>
                <div class="index-rows">
                    <div v-for="attachment in attachments.data" :key="attachment.id" class="box is-marginless">
                        <columns>
                            <column>
                                <p>
                                    {{ attachment.name }}
                                </p>
                                <small class="has-text-grey">Uploaded {{ attachment.created_at | asCalendar }}</small>
                            </column>
                            <column class="is-narrow is-flex is-align-items-center">
                                <div class="buttons is-rounded has-addons">
                                    <action-button @click="downloadAttachment(attachment)" class="is-rounded is-small is-primary">
                                        <icon icon="download"/>
                                    </action-button>
                                    <action-button @click="openEditor(attachment)" class="is-rounded is-small is-warning">
                                        <icon type="far" icon="edit"/>
                                    </action-button>
                                </div>
                            </column>
                        </columns>
                    </div>
                </div>
            </column>
        </columns>
        <pager v-if="attachments.data.length" :pageable="attachments" context="Checklist" jump-controls @nav="goToPage" />

        <no-items-to-display :item-count="attachments.data.length"
            heading="There are no attachments available for display." />
    </loader>
</template>
<script>
import { get } from '@/api/request'
import { updateAttachment, uploadAttachment } from '@/modals'
import { mapGetters } from 'vuex'
import { common as backend } from '@/api'
import download from 'downloadjs'
import findIndex from 'lodash/findIndex'

export default {

    data: () => ({
        loading: true,
        filters: {
            search: ''
        },
        attachments: {
            data: []
        },
    }),

    created() {
        if (this.$route.query.search) {
            this.filters.search = this.$route.query.search
        }
        this.loadAttachments()
    },

    methods: {
        loadAttachments() {
            get('v1' + this.$route.fullPath, ({ data }) => {
                this.attachments = data
                this.loading = false
            }, () => {
                this.loading = false
            }, { params: this.filters })
        },
        goToPage(path) {
            this.$router.push(path)
        },
        runSearch() {
            this.$router.push({
                name: 'user-attachments',
                query: this.filters
            })
        },
        clearFilters() {
            this.filters = {
                search: ''
            }
            this.$router.push({
                name: 'user-attachments',
            })
        },
        openUploader() {
            uploadAttachment('user', this.user.id).then(file => {
                if(file) {
                    this.attachments.data.push(file)
                }
            })
        },
        openEditor(attachment) {
            updateAttachment(attachment).then((payload) => {
                if(payload && payload.event === 'updated') {
                    const index = findIndex(this.attachments.data, attachment => attachment.id === payload.file.id)
                    this.attachments.data.splice(index, 1, payload.file)
                }
                if(payload && payload.event === 'deleted') {
                    const index = findIndex(this.attachments.data, attachment => attachment.id === payload.file.id)
                    this.attachments.data.splice(index, 1)
                }
            })
        },
        downloadAttachment(attachment) {
            backend.downloadFile(`/v1/attachment/${attachment.id}/download`, ({ data }) => {
                download(data, attachment.name, data.type);
            }, () => {})
        }
    },

    computed: {
        ...mapGetters('user', [
            'user'
        ])
    },

    watch: {
        '$route': 'loadAttachments'
    }

}
</script>